var Handlebars = require("../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"store") : depth0)) != null ? lookupProperty(stack1,"projectOptions") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    return "Untitled Project";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,__default(require("./helpers/isDefaultLanguage.ts")).call(alias1,(data && lookupProperty(data,"key")),{"name":"isDefaultLanguage","hash":{},"data":data,"loc":{"start":{"line":17,"column":11},"end":{"line":17,"column":35}}}),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":1},"end":{"line":28,"column":12}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	examplePass.localize(\""
    + container.escapeExpression(container.lambda((data && lookupProperty(data,"key")), depth0))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("./helpers/hasContent.ts")).call(alias1,(depth0 != null ? lookupProperty(depth0,"translations") : depth0),{"name":"hasContent","hash":{},"data":data,"loc":{"start":{"line":18,"column":38},"end":{"line":18,"column":68}}}),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":32},"end":{"line":27,"column":10}}})) != null ? stack1 : "")
    + ");\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ", {\n		/**\n		 * These translations will add-up to the ones inside "
    + container.escapeExpression(container.lambda((data && lookupProperty(data,"key")), depth0))
    + ".lproj/pass.strings.\n		 * These are actually here just as example, but they are the same inside pass.strings\n		 */\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"translations") : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":2},"end":{"line":26,"column":11}}})) != null ? stack1 : "")
    + "	}";
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"0") : depth0), depth0))
    + ": \""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"1") : depth0), depth0))
    + "\",\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "import { createPass } from \"passkit-generator\";\n\ntry {\n	const examplePass = await createPass({\n		model: \"path/to/exported/model/"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"store") : depth0)) != null ? lookupProperty(stack1,"projectOptions") : stack1)) != null ? lookupProperty(stack1,"title") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":5,"column":33},"end":{"line":5,"column":130}}})) != null ? stack1 : "")
    + ".pkpass\",\n		certificates: {\n			wwdr: \"./certs/wwdr.pem\",\n			signerCert: \"./certs/signercert.pem\",\n			signerKey: {\n				keyFile: \"./certs/signerkey.pem\",\n				passphrase: \"123456\"\n			}\n		},\n	});\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"store") : depth0)) != null ? lookupProperty(stack1,"translations") : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":1},"end":{"line":29,"column":10}}})) != null ? stack1 : "")
    + "\n	// Generate the stream, which gets returned through a Promise\n	const stream: Stream = examplePass.generate();\n\n	doSomethingWithTheStream(stream);\n} catch (err) {\n	doSomethingWithTheError(err);\n}\n";
},"useData":true});